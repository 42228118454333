const zlavyimg = [


    {
        id: "2",
        url: require("../img/ZLAVY/cerave.jpg"),
        src: require("../img/ZLAVY/cerave_big.jpg"),
        alt: 'Cerave'
    },

    {
        id: "1",
        url: require("../img/ZLAVY/teoxane.png"),
        src: require("../img/ZLAVY/teoxane.png"),
        alt: 'Teoxane'
    },

    {
        id: "4",
        src: require("../img/ZLAVY/ducray.jpg"),
        url: require("../img/ZLAVY/ducray.jpg"),
        alt: 'Ducray'
    },
    {
        id: "1",
        url: require("../img/ZLAVY/avene.jpg"),
        src: require("../img/ZLAVY/avene.jpg"),
        alt: 'Avene'
    }

]

export default zlavyimg;